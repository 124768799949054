body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.select {
  max-height: 10px;
}

.paginator {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.paginator li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: transparent;
  position: relative; /* Додає позиціонування для кращого контролю */
}

.paginator li a {
  padding: 8px 12px;
}

.paginator li:hover {
  background-color: lightgray;
}

.paginator li.selected {
  background-color: gray;
  color: white;
}

.paginator li.disabled {
  color: gray;
  cursor: not-allowed;
}

.paginator li:not(.disabled):active {
  background-color: darkgray;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
